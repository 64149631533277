<template>
  <div class="page page-xtrading-all-publications">
    <PageHeader
      :icon="$t('PUBLICATION.ICONS')"
      :title="$t('PUBLICATION.TITLES')"
    >
      <template slot="nav">
        <small
          ><strong>{{ $tc("PUBLICATION.TITLE_COUNT", count) }}</strong></small
        >
      </template>
    </PageHeader>

    <FormChannelSubscriptionStepper
      :show="showNew"
      @close="showNew = false"
      @create="updateItems"
    />
    <Side
      component="forms/publication/compact/FormPublicationCompact"
      :ids="(selected || []).map((v) => v.id)"
      :value="showEdit"
      @close="closeEdit"
      @create="loadItems"
      @update="loadItems"
      @remove="removeItems"
    />
    <Side
      component="forms/job_publication/compact/FormJobPublicationCompact"
      :ids="(selected || []).map((v) => v.id)"
      :props="{ campaign_id: mainCampaignId, createCampaignJobs: false}"
      :value="showPublish"
      @close="closePublish"
    />
    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="id"
      @confirm="confirmRemoveItems"
      @close="showDialogRemove = false"
      @select="selectItems"
    />
    <List
      :items="items"
      :count="count"
      :offset="offset"
      :limit="limit"
      :pagination="true"
      :loading="loading"
      :selected="selected"
      @applyFilters="applyFilters"
      @resetFilters="resetFilters"
      @select="selectItems"
      @page="changePage"
      @click="selectItem"
      @reload="searchItems"
    >
      <template v-slot:header="">
        <v-col cols="3">
          <v-text-field
            ref="where.job.title"
            hide-details
            outlined
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('COMMON.SEARCH')"
            @keyup="searchItems"
            @click:clear="searchItems"
          />
        </v-col>
        <v-col cols="3" class="pl-0">
          <v-select
            ref="where.status_type"
            v-model="publicationStatusType"
            :items="$t('PUBLICATION.STATUS_TYPE_ITEMS')"
            :label="$t('PUBLICATION.STATUS')"
            item-text="text"
            item-value="value"
            multiple
            hide-details
            outlined
            dense
            @input="searchItems"
          />
        </v-col>
        <v-col cols="2" class="pl-0">
          <v-select
            ref="where.channel_subscription_id"
            :items="channelSubscriptionItems"
            item-text="channel.title"
            item-value="id"
            hide-details
            outlined
            dense
            clearable
            multiple
            :placeholder="$t('CHANNEL.TITLES')"
            :prepend-inner-icon="$t('CHANNEL.ICON')"
            @change="searchItems"
            @click:clear="searchItems"
          />
        </v-col>
        <!-- <v-col cols="2" class="pl-0">
          <v-select
            ref="where.feed_id"
            :items="feedItems"
            item-text="title"
            item-value="id"
            hide-details
            rounded
            outlined
            dense
            clearable
            multiple
            :prepend-inner-icon="$t('FEED.ICON')"
            :label="$t('FEED.TITLE')"
            @change="searchItems"
            @click:clear="searchItems"
          />
        </v-col> -->

        <!-- <div class="col-3 pl-1">
          <v-select
            ref="where.status"
            v-model="status"
            :items="statuses"
            item-text="text"
            item-value="value"
            rounded
            hide-details
            outlined
            dense
            @input="searchItems"
          />
        </div> -->
      </template>

      <template v-slot:filters="">
        <div class="pa-4">
          <v-row>
            <v-col>
              <span class="text-small text-uppercase grey--text">{{
                $tc("INPUT.CATEGORY")
              }}</span>
              <v-select
                ref="where.xtramile_occupation_id"
                :items="categoryItems"
                item-text="title"
                item-value="id"
                hide-details
                outlined
                dense
                clearable
                multiple
                prepend-inner-icon="mdi-shape"
                :placeholder="$tc('INPUT.CATEGORY')"
              />
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col>
              <span class="text-small text-uppercase grey--text">{{
                $tc("JOB.TITLE")
              }}</span>
              <v-select
                ref="where.feed_id"
                :items="feedItems"
                item-text="title"
                item-value="id"
                hide-details
                outlined
                dense
                clearable
                multiple
                prepend-inner-icon="mdi-rss"
                :placeholder="$tc('JOB.TITLE')"
              />
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="7">
              <span class="text-small text-uppercase grey--text">{{
                $tc("INPUT.LOCATION_CITY")
              }}</span>
              <v-autocomplete
                :label="$tc('INPUT.LOCATION_CITY')"
                outlined
                dense
                clearable
                ref="where.location_city"
                :items="cities"
              />
            </v-col>
            <v-col cols="5">
              <span class="text-small text-uppercase grey--text">{{
                $tc("INPUT.LOCATION_COUNTRY")
              }}</span>
              <v-select
                ref="where.location_country"
                :items="countries"
                item-text="text"
                item-value="value"
                hide-details
                outlined
                dense
                clearable
                prepend-inner-icon="mdi-earth"
                :placeholder="$tc('INPUT.LOCATION_COUNTRY')"
              />
            </v-col>
          </v-row>
        </div>
      </template>

      <template v-slot:nav="">
        <!-- <v-btn icon @click="searchItems"><v-icon>mdi-reload</v-icon></v-btn> -->
      </template>

      <template v-slot:selections="">
        <v-col cols="12" class="pt-0 pl-0">
          <div>
            <small
              ><strong>{{
                $tc("COMMON.SELECTED", +selected.length)
              }}</strong></small
            >
          </div>
          <v-btn
            small
            rounded
            depressed
            color="primary"
            class="mr-2"
            @click="selectAllPages"
          >
            <v-icon size="20" class="mr-2">mdi-playlist-plus</v-icon>
            {{ $t("COMMON.SELECT_ALL_PAGES") }}
          </v-btn>

          <v-btn
            small
            rounded
            depressed
            color="primary"
            class="mr-2"
            @click="showPublish = true"
          >
            <v-icon size="20" class="mr-2">mdi-forwardburger</v-icon>
            {{ $t("COMMON.PUBLISH") }}
          </v-btn>

          <v-btn
            small
            rounded
            depressed
            color="error"
            class="mr-2"
            @click="showDialogRemove = true"
          >
            <v-icon size="20" class="mr-2">mdi-delete</v-icon>
            {{ $t("COMMON.REMOVE") }}
          </v-btn>
          <v-btn
            small
            rounded
            depressed
            color="secondary custom black--text"
            @click="selectItems([])"
          >
            <v-icon size="20" class="mr-2">mdi-close</v-icon>
            {{ $t("COMMON.CANCEL") }}
          </v-btn>
        </v-col>
      </template>

      <template v-slot:item-list="item">
        <div
          v-if="
            item.status_type &&
            (item.status_type === 'error' || item.status_type === 'info')
          "
          :class="['list-overlay', item.status_type ? item.status_type : '']"
        />

        <v-list-item-content class="pl-4">
          <v-list-item-title class="primary--text">
            <strong>{{ item.job.title }}</strong>
            <v-icon
              v-if="item.job.parent_id"
              :title="item.job.parent_id"
              color="success"
              class="ml-2"
              size="14"
            >
              {{ $t("COMMON.DUPLICATE_ICON") }}
            </v-icon>
          </v-list-item-title>

          <v-list-item-subtitle>
            <span class="mr-2" v-if="item.job.company_title || item.job.company"
              ><v-icon class="mr-1" size="12">{{ $t("COMPANY.ICON") }}</v-icon
              >{{ item.job.company_title || item.job.company }}</span
            >
            <span class="mr-2" v-if="item.job.location"
              ><v-icon class="mr-1" size="12">mdi-map-marker</v-icon
              >{{
                item.job.location_city + ", " + item.job.location_city_code
              }}</span
            >
            <span class="mr-2" v-if="item.job.xtramile_sector_id"
              ><v-icon class="mr-1" size="12">mdi-bookmark</v-icon
              >{{ $t("JOB.SECTOR." + item.job.xtramile_sector_id) }}</span
            >
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <status-label x-small :item="item" />
        </v-list-item-action>

        <v-list-item-action class="pr-2 pl-2">
          <v-img contain width="70px" :src="getImage(item.channel)" />
        </v-list-item-action>
        <v-divider vertical />
        <v-list-item-action class="pl-2 pr-2 list-price">
          <div class="text-small text-uppercase">
            <small>{{ item.method }}</small>
          </div>
          <div v-if="item.method !== 'credit'">
            <strong>€ {{ (+item.price || 0).toFixed(2) }}</strong>
          </div>
          <div v-else>
            <strong>Cr. {{ item.price }}</strong>
          </div>
        </v-list-item-action>
        <v-divider vertical />

        <v-list-item-action class="ml-3 date">
          {{ $moment(item.updated_at).fromNow() }}
        </v-list-item-action>

        <v-list-item-action>
          <v-btn icon class="ml-2" @click.stop="removeItem(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <!-- <a
              class="ml-2"
              :href="item.job.url"
              :title="item.job.url"
              target="blank"
            >
              <v-btn icon>
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </a> -->
        </v-list-item-action>
      </template>
    </List>
  </div>
</template>

<style>
.list-price {
  width: 65px;
}
</style>

<script>
import PageHeader from "@/components/PageHeader";
import Side from "@/components/Side";
import DialogRemove from "@/components/DialogRemove";
import List from "@/components/List";
import StatusLabel from "@/components/StatusLabel";
import FormChannelSubscriptionStepper from "@/forms/channel_subscription/stepper/FormChannelSubscriptionStepper";

export default {
  name: "PageXDiffusionAllPublications",
  components: {
    Side,
    DialogRemove,
    List,
    StatusLabel,
    PageHeader,
    FormChannelSubscriptionStepper,
  },
  data: (_this) => ({
    loading: false,
    items: [],
    count: 0,
    offset: 0,
    limit: 100,
    page: 1,
    selected: [],
    showPublish: false,
    showNew: false,
    showEdit: false,
    showDialogRemove: false,
    showDialogPermissionEdit: false,
    timeout: null,
    status: [1],
    methods: [],
    countries: [],
    cities: [],
    startOfDay: new Date(
      `${new Date().toISOString().substr(0, 10)}T00:00:00.000Z`
    ),
    endOfDay: new Date(
      `${new Date().toISOString().substr(0, 10)}T23:59:59.999Z`
    ),
    feedItems: [],
    // filter settings
    publicationStatusType: undefined,
    channelSubscriptionItems: [],
    categoryItems: [],
    mainCampaignId: "",
  }),
  props: ["update", "select", "item"],

  watch: {
    update() {
      this.loadItems();
    },
    select(items) {
      this.selected = items;
    },
    item() {
      this.loadChannelSubscriptionItems();
    },
  },
  mounted() {
    this.$queryFilters().get();
    this.loadChannelSubscriptionItems();
    this.loadFeedItems();
    this.searchItems();
    this.loadMainCampaign();
  },
  computed: {
    paramsId() {
      return this.$router.currentRoute.params.id || undefined;
    },
    itemId() {
      return this.$router.currentRoute.query.edit || undefined;
    },
  },

  created() {
    this.loadCountriesItems();
  },

  methods: {
    loadMainCampaign() {
      this.$services.api_programmatic.campaign
        .search({ where: { status: [1], title: "DefaultCampaign" } })
        .then((response) => {
          this.campaignItems = response.data;
          this.mainCampaignId = response.data[0].id;
        });
    },

    loadCountriesItems() {
      const td = ({ name: text, code: value }) => ({ text, value });
      this.$services.api_programmatic.location_country
        .search()
        .then((response) => {
          if (response.data) this.countries = response.data.map(td);
          else this.countries = [];
        });
    },

    getImage(item, size = "original") {
      const url = `${process.env.VUE_APP_ASSETS}/channels/`;
      return `${url + item.controller_name.toLowerCase()}.png`;
    },

    getReportCount(item) {
      const report = item.job.status_report;
      if (report)
        return Object.keys(report).filter((k) => typeof report[k] === "object")
          .length;
      return 0;
    },

    formatLocation(str) {
      return (str || "").split(",").slice(0, 2).join(",");
    },

    getFeedItem(feed_id, field) {
      for (let i = 0, len = this.feedItems.length; i < len; i++) {
        const c = this.feedItems[i];
        if (c.id === feed_id) return field ? c[field] : c;
      }
      return "?";
    },

    loadFeedItems() {
      this.$services.api_programmatic.feed
        .search({ where: { status: [1] } })
        .then((response) => {
          this.feedItems = response.data;
        });
    },

    loadChannelSubscriptionItems() {
      this.$services.api_programmatic.channel_subscription
        .search({
          where: {
            status: [1],
            channel: {required: true},
          },
        })
        .then((response) => {
          this.channelSubscriptionItems = response.data;
        });
    },

    loadCategoryItems() {
      // this.$services.api_programmatic.publication_category.search({where: { status: [-1, 0, 1] }})
      //   .then(response => {
      //     this.categoryItems = response.data
      //   })
    },

    // Request

    removeItems() {
      this.showEdit = false;
      this.$router.push(`/xdiffusion/campaign/${this.campaignId}`);
      this.loadItems();
    },

    searchItems(element) {
      this.selected = [];
      this.loading = true;
      // debounce request
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.loadItems();
      }, 100);
    },

    loadItems(options = {}) {
      this.loading = true;
      this.$queryFilters().save();
      const defaultOptions = {
        order: [["updated_at", "DESC"]],
        mode: "list",
        page: this.page || 1,
        limit: this.limit,
      };

      const query = this.$queryFormat({
        where: { status: 1, campaign_id: this.paramsId, job: {required:true},
          campaign: {required:true},
          channel_subscription: {required:true},
          channel: {required:true}},
        options: Object.assign({}, defaultOptions, { options }),
      });

      this.$emit("childData", { queryPublicationList: query });
      this.$services.api_programmatic.publication
        .search(query)
        .then((response) => {
          if (response.data) {
            this.items = response.data.items;
            this.count = response.data.count;
            this.offset = response.data.offset;
            this.limit = response.data.limit;
          } else {
            this.items = [];
            this.count = 0;
            this.offset = 0;
            this.limit = 0;
          }
          this.updateSelected();
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false;
        });
    },

    changePage(page) {
      this.page = +page;
      this.searchItems();
    },

    confirmRemoveItems() {
      const td = (v) => v.id;
      const ids = this.selected.map(td);
      this.$services.api_programmatic.publication
        .remove({ where: { id: ids } })
        .then(response => {
          this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
          this.selected = []
          this.showDialogRemove = false
          this.loadItems()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
    },

    updateSelected() {
      const selected = [...this.selected];
      const items = [...this.items];
      for (let i = 0, lenI = selected.length; i < lenI; i++) {
        const a = selected[i];
        for (let j = 0, lenJ = items.length; j < lenJ; j++) {
          const b = items[j];
          if (a.id === b.id) {
            selected[i] = b;
            break;
          }
        }
      }
      this.selected = selected;
    },

    // Mutliple items

    removeSelected() {
      this.showDialogRemove = true;
    },

    editSelected() {
      this.showEdit = true;
      if (this.selected.length > 0) {
        const item = this.selected[0];
        this.$router.push(`/xdiffusion/publications/?edit=${item.id}`);
      }
    },

    selectItem(item) {
      for (let i = 0, len = this.selected.length; i < len; i++) {
        if (this.selected[i].id === item.id) return this.selected.splice(i, 1);
      }
      return this.selected.push(item);
    },

    selectItems(arr) {
      this.selected = arr;
    },

    closeEdit() {
      this.showEdit = false;
      this.$router.push(`/xdiffusion/publications/`);
    },

    closePublish() {
      this.showPublish = false;
      this.$router.push(`/xdiffusion/publications/`);
    },

    selectAllPages(options) {
      this.loading = true;
      const defaultOptions = {
        order: [["updated_at", "DESC"]],
        attributes: ["id", "updated_at"],
        limit: 100000,
      };
      const query = this.$queryFormat({
        where: { status: 1 },
        options: Object.assign({}, defaultOptions, { options }),
      });

      this.$services.api_programmatic.publication
        .search(query)
        .then((response) => {
          if (response.data) {
            this.selected = response.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // Single item

    updateItems() {
      this.loadItems();
    },

    newItem() {
      this.selected = [];
      this.showNew = true;
    },

    editItem(item) {
      this.selected = [item];
      this.$router.push(`/xdiffusion/publications/?edit=${item.id}`);
      this.showEdit = true;
    },

    editPermissionItem(item) {
      this.selected = [item];
      this.showDialogPermissionEdit = true;
    },

    removeItem(item) {
      this.selected = [item];
      this.showDialogRemove = true;
    },

    goToItem(item) {
      // item = item ? item : this.selected[0]
      // const route = `/job/${  item.job.id}`
      // if (this.$router.currentRoute.path !== route) {
      //   this.$router.push(route)
      // }
    },

    applyFilters() {
      this.searchItems();
    },

    resetFilters() {
      this.applyFilters();
    },
  },
};
</script>
